/* utility */
.margin-inline-auto{
  margin-inline: auto;
  width: fit-content;
}
.place-center {
  display: grid;
	place-items: center;
	min-width: 100%;
	min-height: 100%;
}

.button {
  position: relative;
  text-transform: uppercase;
  text-overflow: ellipsis;
  letter-spacing: .1em;
  color: hsl(188, 35%, 92%);
  background-color: hsl(264, 100%, 64%);
  padding: .25em .75em;
  border-radius: .25em;
  transition: background-color .5s;
}
.button:hover{
  cursor: pointer;
  background-color: hsl(264, 80%, 54%)
}
.button.button-icon{
  padding: 1em;
  height: 1em;
  width: 1em;
}
.button.button-icon svg{
  position: absolute;
  fill: hsl(188, 35%, 92%);
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
}

.button.disabled{
  pointer-events: none;
  background-color: gray;
}

.vertical-menu {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.input {
  background-color: hsl(240, 2%, 28%);
  color: hsl(188, 35%, 92%);
  width: 100%;
  padding: .4em;
  border-radius: .4em;
}

.scrollable_parent{
  position: relative;
  overflow-y: auto;
  margin-block: .5em;
}
.scrollable_box{
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

/* dashboard */
#dashboard {
  display: grid;
  grid-template: auto 1fr / 1fr;
  min-height: 100%;
}

/* header */

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: .5em;
  background-color: hsla(0 0% 0% / 25%);
}

.user_banner{
  display: grid;
  place-items: center;
  width: fit-content;
  grid-template: 1fr / auto auto;
  grid-gap: .25em;
  padding: .25em;
}
.user_banner > img {
  height: 1.25em;
  width: 100%;
  border-radius: 100%;
}

.header__menu {
  display: flex;
  flex-direction: row;
  margin: .5em;
  margin-left: auto;
  gap: .5em;
  align-items: center;
  justify-content: flex-end;
}

/* dashboard panels */

.panel {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.panel__title {
  display: block;
  width: fit-content;
  margin-inline: auto;
  margin-block: .25em;
  padding: .25em 1em;
  text-transform: uppercase;
  text-overflow: ellipsis;
  letter-spacing: .1em;
  border-bottom: solid white .1em;
}

.panel__subtitle {
  display: block;
  width: fit-content;
  margin-inline: auto;
  font-size: 1.5em;
  padding: .5em;
}

.panel__menu {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: .5em;
}
.panel__menu > button {
  margin-left: auto;
}

/* announcements */

.announcement {
  background-color: hsl(240, 7%, 16%);
  border-image-slice: 1;
  border-left: 8px solid #9146ff;
  border-right: 8px solid #9146ff;
  border-image-source: linear-gradient(#9146ff,#ff75e6);
  margin-bottom: .5em;
}
.announcement-title{
  display: flex;
  align-items: center;
  gap: .25em;
  font-size: .8em;
  padding: 0.5em 0.5em 0.5em 1em;
  background-color: hsl(240, 6%, 13%);
  fill: hsl(188, 35%, 92%);
}
.announcement-title > button {
  margin-left: auto;
}
.announcement-content {
  overflow-wrap: anywhere;
  padding: .25em 1em;
}